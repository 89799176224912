export enum ReasonOptionEnum {
  NOT_RELEVANT = 'not_relevant',
  EXPIRED_DEADLINE = 'expired_deadline',
  HIGH_COST = 'high_cost',
  DUE_TO_DELAYS = 'due_to_delays',
  ALTERNATIVE_SOLUTION = 'alternative_solution',
  OTHER = 'other',
  NOT_A_DIRECT_PROVIDER = 'not_a_direct_provider',
  COMMUNICATION_CHALLENGES = 'communication_challenges',
  DISSATISFIED_WITH_THE_CUSTOMER_SERVICE = 'dissatisfied_with_the_customer_service',
  GOOD_PRICE = 'good_price',
  FAST_DELIVERY = 'fast_delivery',
  QUICK_REPLIES = 'quick_replies',
  DETAILED_AND_CLEAR_ANSWERS = 'detailed_and_clear_answers',
  HIGH_RATING = 'high_rating',
  DIRECT_SERVICE_PROVIDER = 'direct_service_provider',
  DIFFICULT_COMMUNICATION_WITH_THE_CUSTOMER = 'difficult_communication_with_the_customer',
  NOT_WITHIN_THE_SCOPE_OF_MY_SERVICES = 'not_within_the_scope_of_my_services',
  TOO_SHORT_NOTICE_OR_EXPIRED_DEADLINE = 'too_short_notice_or_expired_deadline',
  TOO_DELAYED_IN_TIME = 'too_delayed_in_time',
  LATE_REPLIES = 'late_replies',
  PRIME_PROVIDER = 'prime_provider',
  BEST_OPTIONAL_LABEL = 'best_option_label',
}
