import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { has } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { NAME_AFFILIATE_COOKIES, NAME_AFFILIATE_PARAM } from '@ipnote/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cookieService: CookieService) {}

  ngOnInit(): void {
    document.body.setAttribute('ipnote-version', environment.version);
  }
}
