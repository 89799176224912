import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITaskEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root',
})
export class ChangeTaskService {
  subject$: BehaviorSubject<ITaskEntity> = new BehaviorSubject(null);
  updateTaskSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  filterTaskProvidersSubject: Subject<number[]> = new Subject<number[]>();

  constructor() {}

  // Метод для подписки на изменения
  get updateTask$() {
    return this.updateTaskSubject.asObservable();
  }

  get filterTaskProviders$() {
    return this.filterTaskProvidersSubject.asObservable();
  }

  triggerUpdateTask() {
    this.updateTaskSubject.next();
  }

  triggerFilterTaskProviders(providerIds: number[]) {
    this.filterTaskProvidersSubject.next(providerIds);
  }
}
