import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  chatMessagesReceived,
  closeChatWindow,
  endTokenReceived,
  eventReceived,
  initializeChat,
  newMessageReceived,
  openChatAndSendMessage,
  openChatWindow,
  ratingAdded,
  reloadPageReceived,
  returnButtonReceived,
  sendCommand,
  stopResponse,
  streamTokenReceived,
  taskCreatedReceived,
  toggleChatWindow,
  waitingPaymentReceived,
  waitingReceived,
  waitingReceivedBtn,
  welcomeMessageReceived,
} from '../actions/ai-widget.actions';
import { concatMap, filter, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SocketIoService } from '../../app-common/services/socket.io/socket.io.service';
import { SOCKET_NAME_AI_WIDGET } from '@ipnote/const';
import { AiRequestWidgetType, AiResponseType } from '@ipnote/type';
import {
  AiChatResponseCommandEnum,
  AiChatResponseWaitEnum,
  AiWidgetRequestCommandEnum,
  GatewayNamespaces,
} from '@ipnote/enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateAiWidgetChat } from '#selectors';
import { Observable, of } from 'rxjs';
import { StateUser } from '../reducers/user';
import { selectStateUser } from '../selectors/user.selectors';
import { UserSetAiActions, UserSetCommand, UserSetNewRegistration } from '../actions/user.actions';
import { aiWidgetEventService } from 'app/page-modules/ai-widget/services/ai-widget-event.service';
import { Socket } from 'socket.io-client/build/esm/socket';
import { closeNotificationsChanel, setNotification } from '../actions/ai-widget-notifications.actions';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AiWidgetEffects {
  userState$: Observable<StateUser> = this.store.select(selectStateUser);
  private socketInitialized = false;
  private aiActionsMessage: string;
  private socketAiMessages: Socket;

  constructor(
    private actions$: Actions,
    private socketService: SocketIoService,
    private store: Store<AppState>,
    private aiWidgetEventService: aiWidgetEventService,
  ) {
    this.userState$.pipe(untilDestroyed(this)).subscribe((userState: StateUser) => {
      this.aiActionsMessage = userState.aiActions;
    });
  }

  ConnectToChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeChat),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUser)))),
        mergeMap(async ([res, user]) => {
          await this.initializeSocket();
          await this.waitForSocket();

          this.socketAiMessages.on(SOCKET_NAME_AI_WIDGET, async (res: AiResponseType) => {
            const { command } = res;

            switch (command) {
              case AiChatResponseCommandEnum.WELCOME:
                this.store.dispatch(welcomeMessageReceived(res.message));
                this.sendWelcomeMessage(user);
                this.sendCommandMessage(user);
                break;
              case AiChatResponseCommandEnum.NEW_MESSAGE:
                this.store.dispatch(newMessageReceived(res.message));
                break;
              case AiChatResponseCommandEnum.STREAM_TOKEN:
                this.store.dispatch(streamTokenReceived({ token: res.token }));
                break;
              case AiChatResponseCommandEnum.WAITING:
                if (res.operation === AiChatResponseWaitEnum.HINT_GENERATION) {
                  this.store.dispatch(waitingReceivedBtn());
                } else {
                  this.store.dispatch(waitingReceived({ operation: res.operation }));
                }
                break;
              case AiChatResponseCommandEnum.RETURN_BTN:
                this.store.dispatch(returnButtonReceived({ buttons: res.buttons }));
                break;
              case AiChatResponseCommandEnum.END_TOKEN:
                this.store.dispatch(endTokenReceived());
                break;
              case AiChatResponseCommandEnum.CHAT_MESSAGES:
                this.sendActionsMessage(user);
                this.store.dispatch(chatMessagesReceived({ messages: res.messages, chatId: res.chatId }));
                break;
              case AiChatResponseCommandEnum.WAITING_PAYMENT:
                this.store.dispatch(waitingPaymentReceived({ serviceType: res.serviceType }));
                break;
              case AiChatResponseCommandEnum.CREATED_TASK:
                this.store.dispatch(taskCreatedReceived({ taskId: res.taskId }));
                break;
              case AiChatResponseCommandEnum.EVENT:
                this.store.dispatch(eventReceived({ eventName: res.eventName }));
                break;
              case AiChatResponseCommandEnum.RATING_ADDED:
                this.store.dispatch(ratingAdded({ isGood: res.isGood }));
                break;
              case AiChatResponseCommandEnum.RELOAD_PAGE:
                this.store.dispatch(reloadPageReceived());
                break;
              case AiChatResponseCommandEnum.COMPLETE_TOOL:
                this.aiWidgetEventService.processEvent(res);
                break;
            }
          });
        }),
      ),
    { dispatch: false },
  );

  toggleChatWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleChatWindow),
        withLatestFrom(
          this.store.select(selectStateAiWidgetChat),
          this.store.select((state) => state.aiWindow.isChatWindowOpen),
        ),
        tap(async ([action, chatState, isChatWindowOpen]) => {
          if (!chatState.initialized) {
            this.store.dispatch(initializeChat());
          }
        }),
        tap(async ([action, chatState, isChatWindowOpen]) => {
          if (isChatWindowOpen) {
            if (!chatState.messages.length) {
              await this.send({
                command: AiWidgetRequestCommandEnum.OPEN,
              });
            }
          } else {
            this.store.dispatch(closeChatWindow());
            this.store.dispatch(setNotification(null));
          }
          this.store.dispatch(closeNotificationsChanel());
        }),
      ),
    { dispatch: false },
  );

  SendChatCommand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendCommand),
        // filter(([_, messages]) => messages.messages.length === 0),
        map(async (action) => {
          await this.send(action);
        }),
      ),
    { dispatch: false },
  );

  stopResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopResponse),

        map(async (action) => {
          await this.send({
            command: AiWidgetRequestCommandEnum.STOP,
          });
        }),
      ),
    { dispatch: false },
  );

  openChatAndSendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openChatAndSendMessage),
        withLatestFrom(this.store.select((state) => state.aiWindow.isChatWindowOpen)),
        tap(async ([action, isChatWindowOpen]) => {
          if (!isChatWindowOpen) {
            // Вызываем toggleChatWindow, чтобы открыть окно и инициализировать чат
            this.store.dispatch(toggleChatWindow());

            // Дождаться, пока окно откроется
            await this.waitForChatOpen();
          }

          // После открытия чата отправляем сообщение
          await this.send({
            command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
            message: action.message,
          });
        }),
      ),
    { dispatch: false },
  );

  private async initializeSocket() {
    if (!this.socketInitialized) {
      this.socketService.setupSocketConnection(GatewayNamespaces.AI_CHAT);
      await this.ensureSocketInitialized();
      this.socketInitialized = true;
    }
  }

  private async ensureSocketInitialized() {
    return new Promise<void>((resolve) => {
      const checkSocket = () => {
        if (this.socketService.getSocket(GatewayNamespaces.AI_CHAT)) {
          resolve();
        } else {
          setTimeout(checkSocket, 100);
        }
      };
      checkSocket();
    });
  }

  private async waitForSocket(): Promise<void> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (this.socketAiMessages) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  private async send(data: AiRequestWidgetType) {
    await this.ensureSocketInitialized();
    this.socketAiMessages = this.socketService.getSocket(GatewayNamespaces.AI_CHAT);
    if (this.socketAiMessages) {
      this.socketAiMessages.emit(SOCKET_NAME_AI_WIDGET, data);
    } else {
      console.error('Socket is not initialized');
    }
  }

  private sendWelcomeMessage(user: StateUser): void {
    if (user.aiActions) {
      this.store.dispatch(
        sendCommand({
          message: user.aiActions,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );
      this.store.dispatch(UserSetAiActions({ aiActions: '' }));
    }

    this.store.dispatch(UserSetNewRegistration(false));
  }

  private sendActionsMessage(user: StateUser): void {
    if (this.aiActionsMessage && !user.newRegistration) {
      this.store.dispatch(
        sendCommand({
          message: this.aiActionsMessage,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );
    }

    this.store.dispatch(UserSetAiActions({ aiActions: '' }));
    this.aiActionsMessage = '';
  }

  private sendCommandMessage(user: StateUser) {
    if (user.command) {
      this.store.dispatch(
        sendCommand({
          message: user.command,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );

      this.store.dispatch(UserSetCommand({ command: '' }));
    }
  }

  private async waitForChatOpen(): Promise<void> {
    return new Promise<void>((resolve) => {
      const subscription = this.store
        .select((state) => state.aiWindow.isChatWindowOpen)
        .pipe(
          filter((isOpen) => isOpen),
          take(1),
        )
        .subscribe(() => {
          subscription.unsubscribe();
          resolve();
        });
    });
  }
}
