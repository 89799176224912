import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { NAME_AFFILIATE_COOKIES, NAME_AFFILIATE_PARAM } from '@ipnote/const';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AffiliateCodeGuard implements CanActivate {
  constructor(private cookieService: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const queryParams = route.queryParams;
    if (queryParams[NAME_AFFILIATE_PARAM]) {
      this.cookieService.set(
        NAME_AFFILIATE_COOKIES,
        queryParams[NAME_AFFILIATE_PARAM],
        0,
        '/',
        `.${environment.baseDomain}`,
      );
    }
    return true;
  }
}
