import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './app-common/guards/auth.guard';
import { AiCreateTaskComponent } from './page-modules/ai-create-task/ai-create-task.component';
import { AuthSignUpAndSaveAiActionsGuard } from './app-common/guards/auth-sign-up-and-save-ai-actions.guard';
import { AffiliateCodeGuard } from './app-common/guards/affiliate-code.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./page-modules/administration/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'public-page',
    loadChildren: () => import('./page-modules/public-page/public-page.module').then((m) => m.PublicPageModule),
  },
  {
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    path: 'desk',
    loadChildren: () => import('./page-modules/desk/desk.module').then((m) => m.DeskModule),
  },
  {
    path: 'auth',
    canActivate: [AffiliateCodeGuard],
    loadChildren: () => import('./page-modules/lobby/lobby.module').then((m) => m.LobbyModule),
  },
  {
    path: 'ai',
    canActivate: [AffiliateCodeGuard, AuthSignUpAndSaveAiActionsGuard],
    loadChildren: () => import('./page-modules/ai/ai.module').then((m) => m.AiModule),
  },
  {
    path: '404',
    loadChildren: () => import('./page-modules/lobby/modules/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'ai-create-task',
    canActivate: [AuthSignUpAndSaveAiActionsGuard],
    component: AiCreateTaskComponent,
  },
  {
    path: '',
    redirectTo: '/desk/default',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
