import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AiToolNameEnum } from '@ipnote/enum';
import {
  AiCompleteAddIPRightData,
  AiCompleteEventData,
  AiCompleteProvidersSelectData,
  AiCompleteTaskData,
  IAiCompleteToolEvent,
  ICompanyEntity,
} from '@ipnote/interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { Router } from '@angular/router';
import { ChangeTaskService } from 'app/page-modules/desk/services/change-task/change-task.service';
import { closeChatWindow, toggleChatWindow } from '../../../store/actions/ai-widget.actions';
import { AiWidgetWindowState } from '../../../store/reducers/ai-widget/ai-widget-window.reducer';
import { selectStateAiWidgetWindow } from '../../../store/selectors/ai-widget.selectors';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class aiWidgetEventService {
  companySelect$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;
  aiWindowState$: Observable<AiWidgetWindowState> = this.store.select(selectStateAiWidgetWindow);
  isOpenedAiWindow: boolean;

  constructor(private store: Store<AppState>, private router: Router, private changeTaskService: ChangeTaskService) {
    this.companySelect$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.selectedCompany = company;
    });

    this.aiWindowState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.isOpenedAiWindow = state.isChatWindowOpen;
    });
  }

  private isAiCompleteTaskData(data: AiCompleteEventData): data is AiCompleteTaskData {
    return 'taskId' in data;
  }

  private isAiCompleteAddIPRightData(data: AiCompleteEventData): data is AiCompleteAddIPRightData {
    return 'ipRightId' in data;
  }

  private isAiCompleteProvidersSelectData(data: AiCompleteEventData): data is AiCompleteProvidersSelectData {
    return 'selectedProviderIds' in data;
  }

  private isSelectedProvidersNotifier(
    event: IAiCompleteToolEvent,
  ): event is IAiCompleteToolEvent & { data: AiCompleteProvidersSelectData } {
    return (
      event.type === AiToolNameEnum.SELECTED_PROVIDERS_NOTIFIER && this.isAiCompleteProvidersSelectData(event.data)
    );
  }

  closeWidget() {
    this.store.dispatch(closeChatWindow());
  }

  handleProviderSelection(selectedProviderIds: number[]): void {
    this.changeTaskService.triggerFilterTaskProviders(selectedProviderIds);
  }

  processEvent(event: IAiCompleteToolEvent): void {
    switch (event.type) {
      case AiToolNameEnum.CREATE_TASK: {
        if (this.isAiCompleteTaskData(event.data)) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/info/provider`;
          this.router.navigateByUrl(url);
        }
        break;
      }
      case AiToolNameEnum.TASK_CONFIRMATION: {
        if (this.isAiCompleteTaskData(event.data)) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/info/provider`;
          this.router.navigate([url], {
            queryParams: { needToPayInvoice: true },
          });

          this.changeTaskService.triggerUpdateTask();
        }
        break;
      }
      case AiToolNameEnum.SELECTED_PROVIDERS_NOTIFIER:
      case AiToolNameEnum.OFFER_PROVIDER_NUMBER:
      case AiToolNameEnum.PROVIDER_SELECTION: {
        if (!this.isAiCompleteTaskData(event.data)) break;
        const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/service-requests`;
        const isUserOnProvidersTab = this.router.url === url;

        if (!isUserOnProvidersTab) {
          if (event.type === AiToolNameEnum.OFFER_PROVIDER_NUMBER) this.closeWidget();
          this.router.navigateByUrl(url);
        }

        if (this.isSelectedProvidersNotifier(event)) this.handleProviderSelection(event.data.selectedProviderIds);
        break;
      }
      case AiToolNameEnum.OFFER_SELECTION: {
        if (this.isAiCompleteTaskData(event.data)) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/offers`;
          this.router.navigateByUrl(url);
        }
        break;
      }
      case AiToolNameEnum.OFFER_REQUEST: {
        if (this.isAiCompleteTaskData(event.data)) {
          const url = `/desk/${this.selectedCompany.id}/tasks/${event.data.taskId}/offers`;
          this.router.navigateByUrl(url);
          if (this.isOpenedAiWindow) {
            this.store.dispatch(toggleChatWindow());
          }
        }
        break;
      }
      case AiToolNameEnum.ADD_IP_RIGHT: {
        if (this.isAiCompleteAddIPRightData(event.data)) {
          const url = `/desk/${this.selectedCompany.id}/oips/${event.data.ipRightId}/info`;
          this.router.navigateByUrl(url);
        }
        break;
      }
    }
  }
}
