import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import {
  injectStripe,
  StripeAddressComponent,
  StripeCardComponent,
  StripeElementsDirective,
  StripeLinkAuthenticationComponent,
  StripePaymentElementComponent,
  StripeService as StripeServiceLibrary,
} from 'ngx-stripe';
import {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
  StripeCardElementChangeEvent,
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StripeService } from '../../../../services/stripe/stripe.service';
import { Stripe } from 'stripe';
import { DialogService } from '../../../../../../app-common/services/dialogs/dialog.service';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../../../../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddressMode } from '@stripe/stripe-js/dist/stripe-js/elements/address';
import { StripePaymentMethod } from '@ipnote/enum';
import { switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ipnote-company-billing-payment-methode-dialog',
  standalone: true,
  imports: [
    MatIconModule,
    TranslocoModule,
    MatTabsModule,
    MatButtonModule,
    NgForOf,
    StripeCardComponent,
    MatInputModule,
    StripeAddressComponent,
    StripeLinkAuthenticationComponent,
    StripePaymentElementComponent,
    StripeElementsDirective,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgIf,
  ],
  templateUrl: './company-billing-payment-methode-dialog.component.html',
  styleUrls: ['./company-billing-payment-methode-dialog.component.scss'],
})
export class CompanyBillingPaymentMethodeDialogComponent implements OnInit {
  stripeKey = environment?.stripe?.stripeKey;
  stripeCustomer: Stripe.Customer;
  loadingStripeCustomer = true;
  updatingPaymentMethodLoading = false;
  creatingPaymentMethodLoading = false;
  StripePaymentMethod = StripePaymentMethod;
  activeTab = StripePaymentMethod.OTHER_PAYMENT_METHOD;
  tabs = [{ icon: 'credit_card', text: 'New card', value: StripePaymentMethod.OTHER_PAYMENT_METHOD }];
  stripe = injectStripe(this.stripeKey);
  clientSecret: string;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
    },
  };

  defaultBillingAddressOptions: StripeAddressElementOptions = {
    mode: 'billing',
    defaultValues: {
      name: this.data?.billing_details.name,
      address: {
        city: this.data?.billing_details.address.city,
        country: this.data?.billing_details.address.country,
        line1: this.data?.billing_details.address.line1,
        postal_code: this.data?.billing_details.address.postal_code,
      },
    },
  };

  billingAddressOptions = {
    mode: 'billing' as AddressMode,
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  isFilledBillingAddress = true; // изменить true на false при возвращении биллинга
  isFilledCard = false;
  billingAddressParams: Stripe.PaymentMethodUpdateParams = {};
  newPaymentMethodParams: Stripe.PaymentMethodCreateParams = {};
  isPaymentMethodUpdated = false;
  isPaymentMethodNotUpdated = false;
  isPaymentMethodCreated = false;
  isPaymentMethodNotCreated = false;

  @ViewChild(StripeElementsDirective) elementsDirective: StripeElementsDirective;

  constructor(
    public dialogRef: MatDialogRef<CompanyBillingPaymentMethodeDialogComponent>,
    private stripeServiceLibrary: StripeServiceLibrary,
    private stripeService: StripeService,
    private dialogs: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.setupIntent();
    this.stripe = this.stripeServiceLibrary.stripe;
    if (this.data) {
      this.tabs.unshift({
        icon: 'credit_card',
        text: `****${this.data?.card.last4}`,
        value: StripePaymentMethod.DEFAULT,
      });

      this.activeTab = StripePaymentMethod.DEFAULT;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  setActiveTab(value: StripePaymentMethod) {
    this.activeTab = value;
  }

  onChange(ev: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event) {
      displayError.textContent = event.type;
    } else {
      displayError.textContent = '';
    }
  }

  cardChange($event: StripePaymentElementChangeEvent) {
    this.isFilledCard = $event.complete;
  }

  billingAddressChange($event: StripeAddressElementChangeEvent) {
    this.isFilledBillingAddress = $event.complete;

    this.billingAddressParams.billing_details = {
      address: $event.value.address,
      name: $event.value.name,
    };
  }

  newBillingAddressChange($event: StripeAddressElementChangeEvent) {
    this.isFilledBillingAddress = $event.complete;

    this.newPaymentMethodParams.billing_details = {
      address: $event.value.address,
      name: $event.value.name,
    };
  }

  updatePaymentMethod() {
    this.updatingPaymentMethodLoading = true;

    this.stripeService
      .updatePaymentMethod(this.data.id, { params: this.billingAddressParams || null })
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.isPaymentMethodUpdated = !!result;
        },
        (error) => {
          this.isPaymentMethodNotUpdated = true;
        },
        () => (this.updatingPaymentMethodLoading = false),
      );
  }

  createPaymentMethod() {
    this.creatingPaymentMethodLoading = true;

    this.stripeServiceLibrary
      .confirmSetup({
        elements: this.elementsDirective._elements,
        redirect: 'if_required',
      })
      .pipe(
        switchMap((setupIntentResult) => {
          return this.stripeService.setDefaultPaymentMethod({
            paymentMethodId: setupIntentResult.setupIntent.payment_method as string,
          });
        }),
        untilDestroyed(this),
      )
      .subscribe(
        (result) => {
          this.isPaymentMethodCreated = true;
        },
        (error) => {
          this.isPaymentMethodNotCreated = true;
        },
        () => (this.creatingPaymentMethodLoading = false),
      );
  }

  setupIntent() {
    this.stripeService.setupIntent().subscribe(
      (result) => {
        this.clientSecret = result.clientSecret;
        this.elementsOptions.clientSecret = this.clientSecret;
        this.loadingStripeCustomer = false;
      },
      (error) => this.dialogs.error(error),
      () => (this.loadingStripeCustomer = false),
    );
  }
}
