import { ErrorResponseCreate } from '@ipnote/shared';
import { Action, createReducer, on } from '@ngrx/store';
import {
  UserForgotPassword,
  UserForgotPasswordConfirm,
  UserForgotPasswordConfirmError,
  UserForgotPasswordConfirmSuccess,
  UserForgotPasswordError,
  UserForgotPasswordSuccess,
} from '../../actions/user.actions';

export interface StateServerRes {
  waitingServerResponse: boolean;
  isSendingRecoveryPassword: boolean;
  error: ErrorResponseCreate;
}

export interface StateForgotPassword {
  request: StateServerRes;
  confirm: StateServerRes;
}

export const initialStateForgotPassword: StateForgotPassword = {
  request: {
    waitingServerResponse: false,
    isSendingRecoveryPassword: false,
    error: null,
  },
  confirm: {
    waitingServerResponse: false,
    isSendingRecoveryPassword: false,
    error: null,
  },
};

const forgotPassword = createReducer(
  initialStateForgotPassword,
  on(UserForgotPassword, (state) => ({
    ...initialStateForgotPassword,
    request: {
      waitingServerResponse: true,
      isSendingRecoveryPassword: false,
      error: null,
    },
  })),
  on(UserForgotPasswordSuccess, (state) => ({
    ...initialStateForgotPassword,
    request: {
      ...initialStateForgotPassword.request,
      isSendingRecoveryPassword: true,
      error: null,
    },
  })),
  on(UserForgotPasswordError, (state, { error }) => ({
    ...initialStateForgotPassword,
    request: {
      waitingServerResponse: false,
      isSendingRecoveryPassword: true,
      error,
    },
  })),
  on(UserForgotPasswordConfirm, (state) => ({
    ...initialStateForgotPassword,
    confirm: {
      waitingServerResponse: true,
      isSendingRecoveryPassword: false,
      error: null,
    },
  })),
  on(UserForgotPasswordConfirmSuccess, (state) => ({
    ...initialStateForgotPassword,
    confirm: {
      ...initialStateForgotPassword.request,
      isSendingRecoveryPassword: true,
    },
  })),
  on(UserForgotPasswordConfirmError, (state, { error }) => ({
    ...initialStateForgotPassword,
    confirm: {
      waitingServerResponse: false,
      isSendingRecoveryPassword: true,
      error,
    },
  })),
);

export function forgotPasswordReducerDefault(state: StateForgotPassword | undefined, action: Action) {
  return forgotPassword(state, action);
}
