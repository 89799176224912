<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title class="whitespace-normal line-clamp-2">
      Are you certain you would like to confirm the offer from "{{ data.name }}"?
    </h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content">
    <div class="content-title">Kindly indicate the reason for selecting this provider:</div>
    <form [formGroup]="form">
      <mat-radio-group color="primary" class="radio-group" formControlName="option">
        <mat-radio-button [value]="enums.reasonOptionEnum.GOOD_PRICE">Good price</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.FAST_DELIVERY">Fast delivery</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.QUICK_REPLIES">Quick replies</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.PRIME_PROVIDER">Trusted provider</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.DETAILED_AND_CLEAR_ANSWERS">Detailed and clear answers</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.HIGH_RATING">High rating</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.DIRECT_SERVICE_PROVIDER">Direct service provider</mat-radio-button>
        <mat-radio-button [value]="enums.reasonOptionEnum.BEST_OPTIONAL_LABEL">Best option label</mat-radio-button>
        <div class="otherReason">
          <mat-radio-button [value]="enums.reasonOptionEnum.OTHER" class="button">Other</mat-radio-button>
          <input formControlName="text" class="other-reason-input" type="text" />
        </div>
      </mat-radio-group>
    </form>
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="decline()">CANCEL</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="
        form.get('option').value === null ||
        (form.get('option').value === 'other' && form.get('text').value.length <= 0)
      "
      (click)="confirm()"
    >
      {{ 'confirm_and_pay' | transloco }}
    </button>
  </mat-dialog-actions>
</div>
