import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  StripeAddressElementChangeEvent,
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../../../../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import {
  StripeAddressComponent,
  StripeCardComponent,
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardGroupDirective,
  StripeCardNumberComponent,
  StripeElementsDirective,
  StripeElementsService,
  StripePaymentElementComponent,
  StripeService as StripeServiceLibrary,
} from 'ngx-stripe';
import { MatInputModule } from '@angular/material/input';
import { switchMap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { StripeService } from '../../../page-modules/desk/services/stripe/stripe.service';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../services/dialogs/dialog.service';
import * as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripePaymentMethod } from '@ipnote/enum';
import { BankAccountsList } from '@ipnote/interface';
import { AddressMode } from '@stripe/stripe-js/dist/stripe-js/elements/address';

export interface PayInvoiceDate {
  stripeInvoiceId: string;
  taskId: number;
}

@UntilDestroy()
@Component({
  selector: 'ipnote-stripe-payment-form',
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    StripeCardNumberComponent,
    StripeCardExpiryComponent,
    StripeCardCvcComponent,
    StripeCardGroupDirective,
    StripeElementsDirective,
    StripePaymentElementComponent,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    StripeCardComponent,
    NgIf,
    TranslocoModule,
    MatProgressSpinnerModule,
    StripeAddressComponent,
    NgForOf,
    NgStyle,
  ],
  templateUrl: './stripe-payment-form.component.html',
  styleUrls: ['./stripe-payment-form.component.scss'],
  providers: [StripeElementsService, StripeServiceLibrary],
})
export class StripePaymentFormComponent implements OnInit {
  stripeKey = environment?.stripe?.stripeKey;
  clientSecret: string;
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
    },
  };
  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
    },
    fields: {
      billingDetails: {
        address: {
          country: 'auto',
          postalCode: 'auto',
        },
      },
    },
  };
  billingAddressOptions = {
    mode: 'billing' as AddressMode,
  };
  billingAddress: any;
  isFilledBillingAddress: boolean;
  isInvoicePaid = false;
  isProcessing = false;
  isInvoiceNotPaid = false;
  url = 'mailto:support@ipnote.pro';
  isDisabledPayButton = true;
  stripeCustomer: any;
  bankAccount: BankAccountsList;
  stripeInvoice: any;
  defaultPaymentMethodCard: any;
  loadingStripeCustomer = true;
  loadingStripeInvoice = true;
  totalAmount = '';
  dueDate = '';
  isPaymentLoading = false;
  activeTab = StripePaymentMethod.OTHER_PAYMENT_METHOD;
  tabs = [];
  StripePaymentMethod = StripePaymentMethod;
  paymentMethodType: string;

  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

  constructor(
    public dialogRef: MatDialogRef<StripePaymentFormComponent>,
    private stripeServiceLibrary: StripeServiceLibrary,
    private stripeService: StripeService,
    @Inject(MAT_DIALOG_DATA) public data: PayInvoiceDate,
    private dialogs: DialogService,
  ) {}

  get cardData() {
    if (!this.defaultPaymentMethodCard) return;
    return `${this.defaultPaymentMethodCard.brand} ****${this.defaultPaymentMethodCard.last4}, ${this.defaultPaymentMethodCard.exp_month}/${this.defaultPaymentMethodCard.exp_year}`;
  }

  ngOnInit(): void {
    this.stripeService.getInvoiceClientSecret(this.data.stripeInvoiceId).subscribe((clientSecret) => {
      this.elementsOptions.clientSecret = clientSecret.secret;
    });

    this.getCustomer();
    this.getInvoiceForTask();
  }

  newBillingAddressChange($event: StripeAddressElementChangeEvent) {
    this.isFilledBillingAddress = $event.complete;

    this.billingAddress = {
      billing_details: {
        address: $event.value?.address,
        name: $event.value?.name,
      },
    };
  }

  close(): void {
    this.dialogRef.close({ isInvoicePaid: this.isInvoicePaid });
  }

  pay(): void {
    this.isPaymentLoading = true;
    this.stripeServiceLibrary
      .confirmPayment({
        elements: this.paymentElement.elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: this.billingAddress?.billing_details,
          },
        },
      })
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.isPaymentLoading = false;
        if (result.error) {
          this.isInvoiceNotPaid = true;
          console.log(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            this.isInvoicePaid = true;
          }
          if (result.paymentIntent.status === 'processing') {
            this.isProcessing = true;
          }
        }
      });
  }

  showInvoice() {
    const url = this.stripeInvoice.hosted_invoice_url;
    window.open(url, '_blank');
  }

  downloadInvoice(fileUrl: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  cardChange(event: StripePaymentElementChangeEvent) {
    this.isDisabledPayButton = !event.complete;
    this.paymentMethodType = event.value.type;
  }

  getCustomer() {
    this.stripeService
      .getCustomer()
      .pipe(
        switchMap((result) => {
          this.stripeCustomer = result;
          this.defaultPaymentMethodCard = this.stripeCustomer.invoice_settings.default_payment_method?.card;
          if (this.defaultPaymentMethodCard) {
            this.tabs.push({
              icon: null,
              text: `Pay with ${this.cardData}`,
              value: StripePaymentMethod.DEFAULT,
            });
            this.tabs.push({
              icon: null,
              text: 'Pay with other payment methods',
              value: StripePaymentMethod.OTHER_PAYMENT_METHOD,
            });
            this.activeTab = StripePaymentMethod.DEFAULT;
          }
          return this.stripeService.getCustomerBankAccount(this.stripeCustomer.id);
        }),
        untilDestroyed(this),
      )
      .subscribe(
        (bankAccount) => {
          this.bankAccount = bankAccount;
          this.loadingStripeCustomer = false;
        },
        (error) => {
          this.dialogs.error(error);
          this.loadingStripeCustomer = false;
        },
      );
  }

  getInvoiceForTask() {
    this.stripeService
      .getInvoiceForTask(this.data.taskId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.stripeInvoice = result;
          this.totalAmount = this.splitLastTwoDigits(this.stripeInvoice.amount_due);
          this.dueDate = this.formatDueDate(this.stripeInvoice.due_date);
          this.loadingStripeInvoice = true;
        },
        (error) => this.dialogs.error(error),
        () => (this.loadingStripeInvoice = false),
      );
  }

  splitLastTwoDigits(number) {
    const str = number.toString();
    const lastTwoDigits = str.slice(-2);
    const wholePart = str.slice(0, -2) || '0';
    const formattedWhole = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedDecimals = lastTwoDigits.padStart(2, '0');

    return `$${formattedWhole}.${formattedDecimals}`;
  }

  formatDueDate(timestamp: number): string {
    return `Due ${moment.unix(timestamp).format('D MMM YYYY')}`;
  }

  setActiveTab(index: StripePaymentMethod) {
    this.activeTab = index;
  }

  payInvoiceDefaultMethod(paymentMethodId: string = '') {
    this.isPaymentLoading = true;
    this.stripeService
      .payInvoiceDefaultPaymentMethod(this.data.stripeInvoiceId, paymentMethodId)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.isPaymentLoading = false;
        if (result.error) {
          this.isInvoiceNotPaid = true;
          console.log(result.error.message);
        } else {
          if (result.status === 'succeeded') {
            this.isInvoicePaid = true;
          }
          if (result.status === 'processing') {
            this.isProcessing = true;
          }
        }
      });
  }
}
