<div class="modal-close-icon" (click)="close()">
  <mat-icon size="10px">close</mat-icon>
</div>

<ng-container *ngIf="!isPaymentMethodNotCreated && !isPaymentMethodCreated && !isPaymentMethodNotUpdated && !isPaymentMethodUpdated">
  <div class="payment-method-dialog_title">{{ 'change-payment-method' | transloco }}</div>

  <div class="payment-method-wrapper">
    <div *ngIf="data">{{ 'choose-payment-method' | transloco }}</div>
    <div class="payment-method-dialog_tabs">
      <button
        *ngFor="let tab of tabs"
        mat-icon-button
        class="tab-item"
        [class.active]="activeTab === tab.value"
        (click)="setActiveTab(tab.value)"
      >
        <mat-icon>{{ tab.icon }}</mat-icon>
        <div class="tab-text">{{ tab.text }}</div>
      </button>
    </div>

    <div class="payment-method-dialog_content">
      <div *ngIf="data && activeTab === StripePaymentMethod.DEFAULT">
        <p class="mb-2">
          <span class="font-bold">{{ 'default-payment-method' | transloco }}:</span>
          ****{{ data.card.last4 }} ({{ data.card.brand }})
          {{ data.card.exp_month }} / {{ data.card.exp_year }}
        </p>
        <div *ngIf="elementsOptions?.clientSecret">
          <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
<!--            <ngx-stripe-address [options]="defaultBillingAddressOptions" (change)="billingAddressChange($event)"></ngx-stripe-address>-->
          </ngx-stripe-elements>
<!--          <button-->
<!--            mat-raised-button-->
<!--            color="accent"-->
<!--            class="mt-5 w-full"-->
<!--            [disabled]="!isFilledBillingAddress"-->
<!--            (click)="updatePaymentMethod()"-->
<!--          >-->
<!--            <div *ngIf="updatingPaymentMethodLoading; else update" class="flex items-center justify-center">-->
<!--              <span>Loading...</span>-->
<!--              <mat-spinner class="spinner" diameter="20"></mat-spinner>-->
<!--            </div>-->
<!--            <ng-template #update> {{ 'update' | transloco }} </ng-template>-->
<!--          </button>-->
        </div>
      </div>

      <div *ngIf="elementsOptions?.clientSecret && activeTab === StripePaymentMethod.OTHER_PAYMENT_METHOD" class="form-wrapper">
        <img class="powered-by-stripe" src='assets/svg/powered-by-stripe.svg' alt="powered by stripe">
        <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
          <ngx-stripe-payment [options]="paymentElementOptions" (change)="cardChange($event)"></ngx-stripe-payment>
<!--          <ngx-stripe-address [options]="billingAddressOptions" (change)="newBillingAddressChange($event)"></ngx-stripe-address>-->
        </ngx-stripe-elements>
        <button
          mat-raised-button
          color="accent"
          class="mt-5 w-full"
          [disabled]="!isFilledBillingAddress || !isFilledCard"
          (click)="createPaymentMethod()"
        >
          <div *ngIf="creatingPaymentMethodLoading; else save" class="flex items-center justify-center">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
          </div>
          <ng-template #save> {{ 'save' | transloco }} </ng-template>
        </button>
      </div>
    </div>

    <div id="card-errors"></div>
  </div>
</ng-container>

<ng-container *ngIf="isPaymentMethodUpdated">
  <p [innerHTML]="'payment-method-update' | transloco"></p>
</ng-container>

<ng-container *ngIf="isPaymentMethodNotUpdated">
  <p class="inner-message" [innerHTML]="'payment-method-not-update' | transloco"></p>
</ng-container>

<ng-container *ngIf="isPaymentMethodCreated">
  <p [innerHTML]="'payment-method-create' | transloco"></p>
</ng-container>

<ng-container *ngIf="isPaymentMethodNotCreated">
  <p class="inner-message" [innerHTML]="'payment-method-not-create' | transloco"></p>
</ng-container>
